import { SideBlock } from './sideblock';
import { Dialog } from './dialog';
import type { IPresenter, PresenterType } from '../types';

export function getPresenter(mode: PresenterType): IPresenter {
  if (mode === 'sideblock') {
    return new SideBlock();
  } else if (mode === 'dialog') {
    return new Dialog();
  }
  throw new Error('Invalid mode: ' + mode);
}