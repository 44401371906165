import { CSS_TRANSITION } from '../../const';
import { createCrossButton } from '../../utils/createCrossButton';
import { onAnimationEnd } from '../../utils/onAnimationEnd';
import { Presenter } from '../presenter';

export class Dialog extends Presenter {
  init(root: HTMLElement, content: HTMLElement): void {
    super.init(root, content);

    this.root = root;
    this.self = document.createElement('div');
    this.self.style.width = '100%';
    this.self.style.maxWidth = '410px';
    this.self.style.height = '100%';
    this.self.style.maxHeight = '685px';
    this.self.style.position = 'fixed';
    this.self.style.top = '50%';
    this.self.style.left = '50%';
    this.self.style.zIndex = '9999';
    this.self.style.transform = 'translate(-50%,-50%)';
    this.self.style.transition = CSS_TRANSITION;
    this.self.style.borderRadius = '0.5rem';
    this.self.style.overflow = 'hidden';
    this.self.style.display = 'none';
    this.self.setAttribute('role', 'dialog');
    this.self.setAttribute('aria-modal', 'true');
    this.self.setAttribute('aria-hidden', 'true');
    this.self.setAttribute('aria-label', 'Aria Label text');
    this.self.setAttribute('aria-description', 'Aria describtion text');
    //this.self.setAttribute('aria-describtion', ' This is a dialog window which overlays the main content of the page. The modal begins with a heading 1 called &quot;Interesting Modal Title&quot;. Pressing the Close Modal button at the bottom of the modal will close the modal and bring you back to where you were on the page.');
    //this.self.setAttribute('tabindex', '-1');

    const closeButton = createCrossButton(this.hide.bind(this));

    this.self.append(closeButton);
    this.self.append(content);

    this.root.appendChild(this.self);
  }

  show() {
    super.show();
    this.self.style.display = 'block';
    this.self.style.opacity = '1';
    this.self.style.boxShadow = '0 0 1rem 1rem rgba(0,0,0,0.1)';
    this.self.setAttribute('aria-hidden', 'false');
    this.self.focus();
  }
  hide() {
    super.hide();
    this.self.style.opacity = '0';
    this.self.style.boxShadow = 'none';
    onAnimationEnd(() => {
      this.self.style.display = 'none';
      this.self.setAttribute('aria-hidden', 'true');
    });
  }
}