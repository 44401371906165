import { ConditionalObservable, Observer } from "./observable";

export class StateMachine<T> extends ConditionalObservable<T> {
  state: T;
  initialState: T;

  constructor(initialState: T) {
    super();
    this.state = initialState;
    this.initialState = initialState;
  }

  getState() {
    return this.state;
  }

  reset() {
    this.state = this.initialState;
    this.removeAllObservers();
  }

  notifyObservers() {
    super.notifyObservers(this.state);
  }

  addObserver(observer: Observer<T>, state?: T): void {
    super.addObserver(observer, state);

    // notify immediately when:
    // condition matches current state
    if (typeof state !== 'undefined') {
      if (this.state === state) {
        observer(this.state);
      }
    } 
    // or when no condition is given
    else { 
      observer(this.state);
    }
  }

  set(state: T) {
    if (this.state === state) return;
    this.state = state;
    this.notifyObservers();
  }
}
