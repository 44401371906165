import { IIFrame } from './types';

export class IFrame implements IIFrame {
  iframe: HTMLIFrameElement;

  constructor(private url: string) {
    console.log('Iframe created');
  }

  render(): HTMLIFrameElement {
    this.iframe = document.createElement('iframe');
    this.iframe.src = this.url;
    this.iframe.style.width = '100%';
    this.iframe.style.height = '100%';
    this.iframe.style.marginTop = '-1px';
    this.iframe.title = 'Orpheus login';
    
    // Allow the iframe to use the Credential Management API for public key credentials
    // This is necessary to enable login with passkey (WebAuthn) functionality within the iframe
    this.iframe.setAttribute('allow', 'publickey-credentials-get *');


    const sandbox = [
      "allow-scripts",
      "allow-same-origin",
      "allow-popups",
      "allow-forms",
      "allow-modals",
      "allow-top-navigation",
      "allow-storage-access-by-user-activation",
    ]
    //iframe.sandbox.add(...sandbox);
    return this.iframe;
  }

  refresh(): void {
    this.iframe.src = this.url;
  }
}
