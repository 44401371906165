export function createCrossButton(callback: () => void) {
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const container = document.createElement('div');
  container.style.position = 'absolute';
  container.style.right = '0';

  const crossButton = document.createElement('button');
  crossButton.setAttribute('type', 'button');
  


  crossButton.style.background = 'transparent';
  crossButton.style.border = 'none';
  crossButton.style.width = '1.5rem';
  crossButton.style.height = '1.5rem';
  crossButton.style.padding = '0px';
  crossButton.style.marginRight = '1rem';
  crossButton.style.marginTop = '0.5rem';
  crossButton.style.cursor = 'pointer';
  const iconColor = isDarkMode ? '#ffffff' : '#000000';
  crossButton.innerHTML = `<svg fill=${iconColor} viewBox="0 0 24 24"><path d="M13.412 12.005l4.295-4.292a.997.997 0 10-1.412-1.41L12 10.595 7.704 6.293a.997.997 0 10-1.411 1.41l4.295 4.302-4.295 4.292a.998.998 0 101.412 1.41L12 13.415l4.296 4.292a.997.997 0 101.411-1.41l-4.296-4.292z"></path></svg>`;
  crossButton.addEventListener('click', callback);

  container.append(crossButton);

  return container;
}
