import { CSS_TRANSITION } from '../../const';
import { Presenter } from '../presenter';
import { createCrossButton } from '../../utils/createCrossButton';

export class SideBlock extends Presenter {
  init(root: HTMLElement, content: HTMLElement): void {
    super.init(root, content);

    this.self = document.createElement('div');
    this.self.style.width = '100%';
    this.self.style.maxWidth = '410px';
    this.self.style.height = '100%';
    this.self.style.position = 'fixed';
    this.self.style.top = '0';
    this.self.style.backgroundColor = 'rgba(0,0,0,0.5)';
    this.self.style.zIndex = '9999';
    this.self.style.transition = CSS_TRANSITION;
    this.self.style.transform = 'translateX(0)';
    this.self.setAttribute('role', 'modal');

    const closeButton = createCrossButton(() => this.hide());
    this.self.append(closeButton);

    this.self.append(content);

    this.root.appendChild(this.self);
  }

  show() {
    super.show();
    this.self.style.transform = 'translateX(-100%)';
    this.self.style.boxShadow = '0 0 1rem 1rem rgba(0,0,0,0.1)';
  }

  hide() {
    super.hide();
    this.self.style.transform = 'translateX(0)';
    this.self.style.boxShadow = 'none';
  }
}