import { CSS_TRANSITION } from "./const";
import { state } from "./state";
import { BackdropOptions } from "./types";
import { onAnimationEnd } from "./utils/onAnimationEnd";

export class Backdrop {
  root: HTMLElement;
  self: HTMLElement;

  constructor() {
    state.addObserver((state) => {
      if (state === 'shown') {
        this.show();
      } else if (state === 'hidden') {
        this.hide();
      }
    });
  }

  init(root: HTMLElement, options: BackdropOptions): void {
    let blur: boolean = false;
    let eclipse: boolean = false;

    if (typeof options === 'boolean') {
      blur = options;
      eclipse = options;
    } else if(typeof options === 'object') {
      blur = options?.blur ?? true;
      eclipse = options?.eclipse ?? true;
    }

    this.root = root;
    this.self = document.createElement('div');
    this.self.style.height = '100vh';
    this.self.style.width = '100vw';
    this.self.style.position = 'fixed';
    this.self.style.top = '0';
    this.self.style.left = '0';
    this.self.style.zIndex = '9998';
    this.self.style.transition = CSS_TRANSITION;
    this.self.style.display = 'none';

    if(blur) {
      this.self.style.backdropFilter = 'blur(.5rem)';
      //@ts-ignore
      this.self.style.webkitBackdropFilter = 'blur(.5rem)';
    }

    if(eclipse) {
      this.self.style.backgroundColor = 'rgba(0,0,0,0.5)';
    }

    this.self.addEventListener('click', () => state.set('hidden'));
    this.root.appendChild(this.self);
  }

  show(): void {
    this.self.style.display = 'block';
    this.self.style.opacity = '1';
  }

  hide(): void {
    this.self.style.opacity = '0';
    onAnimationEnd(() =>  this.self.style.display = 'none');
  }
}