// todo: Add module import instead of global

import { IFrame } from "./iframe";
import { API, InitProps } from "./types";
import { Backdrop } from "./backdrop";
import { getPresenter } from "./presenter/getPresenter";
import { state } from "./state";
import { normalizeUrl } from "./utils/normalizeUrl";



// if the module has no dependencies, the above pattern can be simplified to
(function (root, factory) {
  // if (typeof define === 'function' && define.amd) {
  //     // AMD. Register as an anonymous module.
  //     define([], factory);
  // } 
  // else if (typeof module === 'object' && module.exports) {
  //     // Node. Does not work with strict CommonJS, but
  //     // only CommonJS-like environments that support module.exports,
  //     // like Node.
  //     module.exports = factory();
  // } 
  // else {
  // Browser globals (root is window)
  //@ts-ignore
  root.AS = {
    //@ts-ignore
    ...root.AS,
    tetheredLogin: factory()
  }
  //}
}(typeof self !== 'undefined' ? self : this, function () {
  const initFactory = ({
    url,
    mode,
    root,
    backdrop,
    onShow,
    onHide,
  }: InitProps): API => {
    if (!root) {
      // try find root
      root = document.getElementById('tethered-login-root');

      if (!root) {
        // create root
        root = document.createElement('div');
        root.id = 'tethered-login-root';
        // add styles
        root.style.display = 'block';
        root.style.position = 'absolute';
        root.style.top = '0';
        root.style.right = '0';
        document.body.appendChild(root);
      }
    }
    
    root.innerHTML = '';

    state.reset();

    const presenter = getPresenter(mode);
    const bd = new Backdrop();
    const iframe = new IFrame(`${normalizeUrl(url)}/tethered-login`);
    const iframeContent = iframe.render();

    presenter.init(root, iframeContent);

    if(backdrop) {
      bd.init(root, backdrop);
    }
    
    if (typeof onShow === 'function') {
        state.addObserver(onShow, 'shown');
    }

    if (typeof onHide === 'function') {
        state.addObserver(onHide, 'hidden');
    }

    // close dialog on escape
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        presenter.hide();
        if(backdrop) {
          bd.hide();
        }

        state.set('hidden');
      }
    });

    // add an event listener to refresh the iframe when the page is loaded from cache
    window.addEventListener('pageshow', function(event) {
      if(event.persisted) {
        iframe.refresh();
      }
    })

    // add an event listener to listen for messages from the frame
    window.addEventListener('message', function(event) {
      if (iframeContent.contentWindow !== event.source) return;
      console.log('Message from tethered login form', event.data);
    });

    function refreshIframe() {
      iframeContent.contentWindow.postMessage('refresh', '*');

      window.addEventListener('message', function(event) {
        if (event.data && event.data === 'refreshed') {
          console.log('Iframe refreshed!');
        }
      });
    }

    const result: API = {
      show: () => {
        state.set('shown');
      },
      hide: (keepAlive = false) => {
        console.log('Hide called', keepAlive);
        presenter.hide();

        if(backdrop) {
          bd.hide();
        }

        refreshIframe();
        state.set('hidden');
      },
    };

    return result;
  }

  return initFactory
}));