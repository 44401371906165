export type Observer<T> = (value: T) => void;


export class Observable<T> {
  protected observers: Set<Observer<T>>;
  
  constructor() {
    this.observers = new Set();
  }

  addObserver(observer: Observer<T>, value?: T) {
    this.observers.add(observer);
  }


  removeObserver(observer: Observer<T>, value?: T) {
    this.observers.delete(observer);
  }

  removeAllObservers() {
    this.observers.clear();
  }

  notifyObservers(value: T) {
    for (const observer of this.observers) {
      observer(value);
    }
  }
}

/**
 * Decorates the observable with conditional observers
 */
export class ConditionalObservable<T> extends Observable<T> {
  protected conditionalObservers: Map<T, Set<Observer<T>>>;

  constructor() {
    super();
    this.conditionalObservers = new Map();
  }

  addObserver(observer: Observer<T>, value?: T) {
    if (value) {
      if (!this.conditionalObservers.has(value)) {
        this.conditionalObservers.set(value, new Set());
      }
      this.conditionalObservers.get(value).add(observer);
    } else {
      super.addObserver(observer);
    }
  }

  removeObserver(observer: Observer<T>, value?: T) {
    if (value) {
      if (this.conditionalObservers.has(value)) {
        this.conditionalObservers.get(value).delete(observer);
      }
    } else {
      super.removeObserver(observer);
    }
  }

  removeAllObservers() {
    super.removeAllObservers();
    this.conditionalObservers.clear();
  }

  notifyObservers(value: T) {
    // notify unconditional observers
    super.notifyObservers(value);

    // notify conditional observers
    if (this.conditionalObservers.has(value)) {
      for (const observer of this.conditionalObservers.get(value)) {
        observer(value);
      }
    }
  }
}