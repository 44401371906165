import { state } from "../state";
import { IPresenter } from "../types";
import { onAnimationEnd } from "../utils/onAnimationEnd";

export abstract class Presenter implements IPresenter {
  root: HTMLElement;
  self: HTMLElement;
  shown: boolean = false;

  constructor() {
    state.addObserver((state) => {
      if (state === 'shown') {
        this.show();
      } else if (state === 'hidden') {
        this.hide();
      }
    });
  }

  init(root: HTMLElement, content: HTMLElement): void {
    this.root = root;
    document.addEventListener('click', (event) => {
      if (this.shown) {
        //@ts-ignore
        const clickOutside = !this.self.contains(event.target) && this.self !== event.target;
        if (clickOutside) {
          state.set('hidden');
        }
      }
    });
  }

  show(): void {
    onAnimationEnd(() =>  this.shown = true);
  }

  hide(): void {
    onAnimationEnd(() =>  this.shown = false);
    state.set('hidden');
  }
}